<template>
  <div id="app">
    <div class="container">
      <top></top>
      <router-view />
      <bottom></bottom>
    </div>
    <!-- /container -->
  </div>
</template>

<script>
import Top from "@/components/Top";
import Bottom from "@/components/Bottom";

export default {
  name: "app",
  components: {
    Top,
    Bottom,
  },
};
</script>

<style>
/* Space out content a bit */
body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Customize container */
@media (min-width: 48em) {
  .container {
    max-width: 50rem;
  }
}
.container-narrow > hr {
  margin: 2rem 0;
}
</style>
