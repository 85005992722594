<template>
      <main role="main">
        <div class="jumbotron">
          <h1 class="display-3">Akitek</h1>
          <p class="lead">IT consultancy (Architecture, Agile, Mobile & Full-Stack)</p>
        </div>
      </main>
</template>

<script>
export default {
  name: 'Main',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}
.jumbotron .btn {
  padding: .75rem 1.5rem;
  font-size: 1.5rem;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
</style>
