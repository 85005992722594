<template>
  <div class="mx-auto" style="width: 200px">
    <p>
      <span> contact (a) akitek be </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
