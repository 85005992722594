<template>
  <footer class="footer">
    <p>&copy; Akitek SPRL - 2016</p>
  </footer>
</template>

<script>
export default {
  name: "Bottom",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Custom page footer */
.footer {
  padding-top: 1.5rem;
  color: #777;
  border-top: 0.05rem solid #e5e5e5;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
